$resources-green: #26b373;

.resources-component {
  margin-top: 5%;
  max-width: 100%;
}
.resources-row {
  width: 100%;
  display: flex;
  min-height: 160px;
}

.resources-li {
  font-family: 'Roboto';
  font-weight: lighter;
  margin-bottom: 3%;
  & > span {
    font-weight: bold;
  }
}

.resources-right {
  padding-left: 3%;
  width: 70%;
  border-left: 2px solid $resources-green;
}

.resources-block {
  border: 3px solid $resources-green;
  margin-right: 10px;
  padding: 10px;
  width: 23%;
  word-wrap: break-word;
  text-align: center;
  & > p {
    font-size: 11pt;
  }

  & > title {
    font-family: 'Roboto';
    font-weight: bold;
    color: $resources-green;
    text-transform: uppercase;
    font-size: 13pt;
  }
}

.resources-left {
  width: 45%;
  display: flex;
  padding-right: 2%;

  & > .text {
    margin-left: 6%;
    width: 70%;
  }
  & > svg {
    width: 25%;
    align-self: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .resources-row {
    display: inline;
  }

  .resources-block {
    width: 100%;
    margin-bottom: 10%;
  }

  .resources-left {
    width: 100%;
    margin-top: 10%;
  }

  .resources-right {
    width: 100%;
    margin-top: 10%;
  }
}
