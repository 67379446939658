@import './custom';
.trainer_layout {
  overflow: hidden;
}

.grid_col {
  padding-left: 0px;
  border-right-style: solid;
  border-right-width: 2px;
  border-right-color: $blue;
  @media (max-width: 576px) {
    border: none;
  }
}

.to_statistics_link:hover {
  text-decoration: none;
  opacity: 60%;
}

.to_statistics_button,
.admin_button {
  cursor: pointer;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 11rem;
  color: $blue;
  border-color: $blue;
  border-style: solid;
  background-color: $gray-200;
  border-width: 1px;
  transition: 0.2s all;

  &:hover {
    opacity: 70%;
  }
  &:active {
    opacity: 100%;
    transform: scale(0.98);
  }

  .admin_button_image {
    width: 1rem;
  }

  &.control {
    max-width: max-content;
    padding: 0.25rem 1em !important;
  }
}

.to_statistics_button {
  @media only screen and (max-width: 640px) {
    width: 100%;
  }
}

.admin_select {
  max-width: 20rem;
}

.trainer {
  background-color: $blue;
  color: white;
  .admin_hero_role_text {
    font-size: 3rem;
    margin-bottom: -4px;
  }
  .admin_hero_dashboard_text {
    font-size: 4.5rem;
  }
  @media only screen and (max-width: 480px) {
    .admin_hero_role_text {
      font-size: 2.5rem;
    }
    .admin_hero_dashboard_text {
      font-size: 4rem;
    }
  }
}

.pointer {
  cursor: pointer;
}

.material {
  font-size: 1.2rem;
  width: 33%;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  .material_image {
    max-width: 1.8rem;
    max-height: 2rem;
  }
  .material_link {
    color: black;
  }
  .document-image {
    height: 36px;
  }
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .row {
    display: flex;
  }
  .row .col {
    flex: 1;
  }
  .row .col:last-child {
  }
  .tabs {
    border-radius: 8px;
    overflow: hidden;
  }

  .tab {
    width: 100%;
    color: black;
    overflow: hidden;
  }
  .tab-label {
    display: flex;
    justify-content: flex-start;

    padding-bottom: 0;
    cursor: pointer;
  }
  .tab-label::after {
    content: '❯';
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
  }
  .tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    background: white;
    transition: all 0.35s;
  }
  .tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
  }
  input:checked + .tab-label::after {
    transform: rotate(90deg) translateX(0.3em);
    margin: 0 0.2em;
  }
  input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
    padding-top: 0;
  }
}

.admin_title {
  width: fit-content;
  font-weight: 300;
  border-bottom: 2px solid $blue;
  .admin_title_header {
    text-align: left;
  }
  .admin_title_panel {
    width: 15rem;
  }
  .trainer_title_materials {
    text-align: center;
    width: fit-content;
  }

  @media (max-width: 768px) {
    width: auto;
  }
}

.surveys_grid {
  text-align: center;
  table-layout: auto;
  thead th {
    font-weight: 300;
    border-color: black;
    background-color: $gray-200;
  }
  tr td {
    border-color: black;
    min-width: 4.5rem;
  }
  .general_grid_icon {
    width: 1.2rem;
    cursor: pointer;
  }
  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: arial, sans-serif;
    color: #212529;
    text-decoration: underline;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }
}

.general_grid {
  text-align: center;
  table-layout: auto;

  thead th {
    font-weight: 300;
    border-color: black;
    background-color: $gray-200;

    button {
      color: #212529;
    }
  }

  tr td {
    border-color: black;

    button {
      color: #212529;
      text-decoration: none;
    }
  }

  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-family: arial, sans-serif;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }

  .selected {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .general_grid_icon {
    width: 1.2rem;

    .update_button {
      cursor: pointer;
    }
  }
  td {
    max-width: 2rem;
    &.large {
      max-width: max-content;
    }
  }
  @media (max-width: 576px) {
    td {
      max-width: max-content;
      &.large {
        max-width: max-content;
      }
    }
  }
}

.grids_pagination {
  display: flex;
  justify-content: flex-end;

  .admin_button {
    width: 6rem;
    margin-left: 1rem;
  }
}

.admin_web_view {
  .react-player-wrapper {
    display: none;
  }
  .alert {
    display: none;
  }
}

.modal-lg {
  max-width: 70rem !important;
}

.modal-sm {
  max-width: 40rem !important;
}

.modal-header {
  border-bottom: none !important;
}

.CRUDPanel_row {
  border: 1px solid #0e75bb;
  width: -webkit-fill-available;
  @media (max-width: 576px) {
    border: none;
  }
}

.CRUDPanel_input {
  padding-bottom: 0px;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 1px solid black;
  min-width: 300px;
}

.CRUDPanel_delete_button {
  border-color: red !important;
  color: red !important;
}

.CRUDPanel_emails_list {
  list-style: none;
}

.CRUDPanel_emails_list_icon {
  width: 1rem;
  cursor: pointer;
}

.CRUDPanel_update_alert {
  width: 40%;
}

.CRUDPanel_option {
  margin-left: 0rem !important;
  margin-right: 0.3rem !important;
}

@media (min-width: 576px) {
  .pl-3 {
    padding-left: 1rem;
  }
}
