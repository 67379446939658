.chip {
  padding: 0.2em;
  span {
    cursor: pointer;
  }
  span:hover {
    color: rgb(247, 148, 32);
  }
  li {
    display: inline;
    label {
      padding: 0.3em 0.8em;
      border-style: solid;
      border-color: #909090;
      display: inline-block;
      white-space: nowrap;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      transition: all 0.2s;
      &::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-weight: 900;
        font-size: 1.2em;
        padding: 0.2em 0.6em 0.2em 0.2em;
        transition: transform 0.3s ease-in-out;
      }
    }
    input[type='radio'],
    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
    }
    input[type='radio']:checked + label,
    input[type='checkbox']:checked + label {
      border: 0.2em solid rgb(14, 117, 187);
      background-color: rgb(14, 117, 187);
      color: white;
      transition: all 0.2s;
    }
    input[type='radio']:focus + label,
    input[type='checkbox']:focus + label {
      border: 0.2em solid rgb(247, 148, 32);
    }
  }
}

.wideChip {
  width: 100%;
  .chipLabel {
    width: 100%;
    background-color: #ecebea;
    border-width: 0.1em;
    color: black;
    border-radius: 0.5em;
    margin: 0.1em 0em;
  }
}

.smallChip {
  .chipLabel {
    background-color: transparent;
    border-width: 0.2em;
    color: #909090;
    border-radius: 2.5em;
    margin: 0.3em 0em;
  }
}

.chipRow {
  justify-content: center;

  @media only screen and (min-width: 640px) {
    justify-content: flex-start;
  }
}

.chipCol {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-width: 5rem;

  label {
    font-size: 1rem;
  }
}

.goalCheckboxWrapper {
  li {
    input[type='checkbox']:disabled + label {
      background-color: #dddddd !important;
      border-color: #dddddd !important;
    }
  }
}

.goalCheckbox {
  border-width: 1px !important;
  white-space: normal !important;
  border-radius: 0.5rem;
}
