.diversity-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.diversity-column-big {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 70%;
}

.diversity-column-small {
  border: 2px solid orange;
  text-align: center;
  flex-direction: column;
  width: 25%;
  margin-left: 3%;
  margin-top: 10%;
}

.diversity-box {
  margin-top: 30px;

  & > .title {
    font-family: 'Roboto';
    font-weight: bold;
    color: orange;
    font-size: 12pt;
    border: 1px solid orange;
    width: 90%;
    margin-left: 5%;
    box-sizing: border-box;
    padding: 2%;
  }

  & > p {
    font-family: 'Roboto';
    text-align: center;
    font-weight: lighter;
    padding: 2px;
    font-size: 10pt;
  }
}

@media only screen and (max-width: 768px) {
  .diversity-column-small {
    width: 100%;
    margin-bottom: 5%;
  }
}
