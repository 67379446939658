@import './custom';

.main {
  #main-content {
    min-height: 50vh;
    overflow: hidden;
  }

  nav.navbar {
    opacity: 1;

    &.bg-dark {
      background-color: #000000 !important;
    }

    .navbar-brand img {
      width: 175px;
    }
  }

  %desktop_margin {
    margin-left: auto;
    margin-right: auto;
  }

  .max_desktop_width_60 {
    @extend %desktop_margin;
    max-width: 60em;
  }

  .max_desktop_width_70 {
    @extend %desktop_margin;
    max-width: 70em;
  }

  .max_desktop_width_80 {
    @extend %desktop_margin;
    max-width: 80em;
    @media (max-width: 768px) {
      max-width: 100% !important;
    }
  }

  .header {
    a {
      text-decoration: none;
    }
    button {
      background-color: white;
      font-size: 1.1rem;
      width: 6rem;
      max-height: 2rem;
      border-radius: 0 !important;
      border-color: $blue;
      color: $blue;
      &:hover {
        background-color: $blue;
        color: white;
      }
    }
  }
  @media print {
    .login_button {
      display: none;
    }
  }
}

.header_link {
  padding-left: 0 !important;
  background-color: white;
  text-decoration: none;
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 1.1rem;
  }
}

.footer {
  background-color: $gray-200;
  position: 'relative';
  bottom: '0';
  left: '0';
  .footer_col_text {
    height: 8rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .footer_link {
    a {
      color: black;
      text-decoration: none;
    }

    border-right-style: solid;
    border-right-width: 1px;
  }

  .footer_link_none {
    border-right-style: none;
  }

  @media print {
    position: fixed;
    bottom: 0px;
    left: 0;
  }
}

@media only screen and (max-width: 480px) {
  #graph-container > svg {
    transform: translate(-82px, -64px);
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  #graph-container > svg {
    transform: translate(-10px, -64px);
  }
}

// TODO: Inspect usage of graph_image and clean up
.graph_image {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  background-repeat: no-repeat;
  background-position: 25vw 13vh;
  z-index: -10;

  @media only screen and (min-width: 640px) {
    background-size: 70%;
    background-position-x: 40vw;
  }

  @media only screen and (min-width: 1024px) {
    background-size: 60%;
    background-position: 35vw 10vh;
  }

  @media only screen and (min-width: 1920px) {
    background-size: 48%;
    background-position: 35vw 4vh;
  }
}

#main_graph_image {
  //background-image: url('/Hero_Graph_Admin.svg');
  background-image: none;
  @media only screen and (min-width: 1024px) {
    background-size: 60%;
    background-position: 40vw 20vh;
  }
  @media only screen and (min-width: 1366px) {
    background-size: 60%;
    background-position: 40vw 10vh;
  }
  @media only screen and (min-width: 1920px) {
    background-size: 50%;
    background-position: 40vw -5vh;
  }
}

#diagnostic_graph_image {
  //background-image: url('/LND-website-header-graphic.svg') !important;
  @media only screen and (min-width: 1024px) {
    background-size: 60%;
    background-position: 40vw 20vh;
  }
  @media only screen and (min-width: 1366px) {
    background-size: 60%;
    background-position: 40vw 10vh;
  }
  @media only screen and (min-width: 1920px) {
    background-size: 50%;
    background-position: 45vw -5vh;
  }
}

#partners_graph_image {
  //background-image: url('/Hero_Graph_Partners.svg') !important;
  background-size: 70%;
  @media only screen and (min-width: 480px) {
    background-position: 50vw 5vh;
  }
  @media only screen and (min-width: 640px) {
    background-size: 60%;
    background-position: 50vw -2vh;
  }
  @media only screen and (min-width: 1024px) {
    background-position: 55vw 2vh;
  }
  @media only screen and (min-width: 1366px) {
    background-position: 55vw -15vh;
  }
  @media only screen and (min-width: 1920px) {
    background-size: 50%;
    background-position: 60vw -30vh;
  }
}

#research_graph_image {
  //background-image: url('/Hero_Graph_Research.svg') !important;
  background-position: 40vw 0vh;
  @media only screen and (min-width: 480px) {
    background-position: 40vw -20vh;
  }
  @media only screen and (min-width: 640px) {
    background-size: 65%;
    background-position: 40vw -10vh;
  }
  @media only screen and (min-width: 1024px) {
    background-size: 65%;
    background-position: 45vw -10vh;
  }
  @media only screen and (min-width: 1920px) {
    background-size: 50%;
    background-position: 50vw -25vh;
  }
}

#about_graph_image {
  //background-image: url('/Hero_Graph_About.svg') !important;
  background-position: 40vw 5vh;
  @media only screen and (min-width: 640px) {
    background-size: 70%;
    background-position: 40vw 0vh;
  }
  @media only screen and (min-width: 1024px) {
    background-size: 70%;
    background-position: 45vw -35vh;
  }
  @media only screen and (min-width: 1920px) {
    background-size: 52%;
    background-position: 45vw -30vh;
  }
}

.cookie-container-class {
  bottom: 20px !important;
}
// This is to customize the cookie banner and center
// the button on mobile
div.cookie-container-class > div:nth-child(2) {
  margin: auto;
}

.cohort-header {
  max-width: 1280px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
  }
}

.cohort_grid {
  text-align: center;
  table-layout: auto;

  * {
    text-align: left;
    border-right: 0 !important;
    border-left: 0 !important;
    border-top: 0 !important;
    border-color: #E8ECEE !important;
    overflow: visible;
    vertical-align: middle;
    width: fit-content;
  }
  .td-center {
    text-align: center;
  }
  th {
    font-weight: 500;
  }
  button {
    background: none;
    color: inherit;
    border: none;
    outline: inherit;
  }
  border: 0px !important;
  margin-bottom: .5rem !important;
  overflow: visible;
  td {
    max-width: 2rem;
    &.large {
      max-width: max-content !important;
    }
  }
  @media (max-width: 576px) {
    td {
      max-width: max-content !important;
      &.large {
        max-width: max-content !important;
      }
    }
  }
}

.border_radious_cohort_grid{
  border-radius: 15px !important;
  border: 1px solid #E8ECEE;
  overflow-x: auto;
}

.grid_update_row {
  text-align: right;
  button {
    background: none;
    color: inherit;
    border: none;
    padding-left: 0.5rem;
    padding-right: 0rem;
    outline: inherit;
  }
}

td .grid_update_row {
  display: flex;
  @media (max-width: 768px) {
    display: block !important;
  }
  justify-content: flex-end;
}

.hidden-on-mobile { 
  @media (max-width: 768px) {
    display: none !important;
  }
}


.hidden {
  visibility: hidden;
  display: none;
}

.general_grid_icon {
  width: 1.2rem;
  background: none;
  color: inherit;
  border: none;
  outline: inherit;
  margin-right: auto;
  margin-left: auto;
  .update_button {
    cursor: pointer;
  }
}

.button_border_blue {
  border: 1px solid #1275bb;
  margin-left: 0.5rem;
  padding-right: 1.5rem;
  padding-top: .2rem;
  padding-left: 1.5rem;
  padding-bottom: .2rem;
  border-radius: 15px !important;
  background-color: transparent;
  color: #1275bb;
  font-size: 16px;
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
  }
}

.button_border_blue:hover {
  background-color: #1275bb;
  color: white;
}

.button_fill_blue {
  border: 1px solid #1275bb;
  background: #1275bb;
  margin-left: 0.5rem;
  padding-right: 1.5rem;
  padding-top: .2rem;
  padding-left: 1.5rem;
  padding-bottom: .2rem;
  border-radius: 15px !important;
  font-size: 16px;
  * { 
    color: white !important; 
    text-decoration: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
  }
}

.cohort-header-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    div { width: 100%;}
  }
}

.button_fill_blue:hover {
  background-color: white;
  * { 
    color: #1275bb !important; 
    text-decoration: none;
  }
}

.underline-blue {
  border-bottom: 2px solid #1275bb;
}

.tr-no-hover:hover {
  --bs-table-accent-bg: transparent !important;
}

.survey-started {
  background-color: #1275bb;
  color: white;
  border-radius: 999999px;
  padding-top: .3rem;
  padding-bottom: .3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  font-size: 16px;
  max-width: 2rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 1rem;
}

.grouped-survey-name {
  display: flex;
  align-items: center;
  width: 100%;
  border: 0px !important;
}

.grid_survey_pagination {
  display: flex;
  justify-content: flex-end;
  padding-bottom: .5rem;
  padding-right: .5rem;
  width: 100%;

  .pagination_button {
    width: 1.5rem;
    background: none;
    color: inherit;
    border: none;
    padding-left: 1rem;
    padding-right: 0rem;
    outline: inherit;
  }

  .pagination_button:disabled {
    opacity: .5;
  }
}

.cohort-search {
  border: 1px #dee2e6 solid;
  border-radius: 15px !important;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
  button {
    background: none;
    color: inherit;
    border: none;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    outline: inherit;
  }
  input:focus-visible {
    outline: 0 !important;
  }
}

.search-input {
  border:none !important;
  background-image:none;
  background-color:transparent;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important; 
}

.view-report-button {
  border: 1px #004578 solid !important;
  border-radius: 5px !important;
  padding-right: .3rem !important;
  padding-left: .3rem !important;
  color: #004578 !important;
  margin-left: .5rem;
  min-width: fit-content;
  white-space: nowrap;
}

.view-report-button:hover {
  color: white !important;
  background-color: #004578;
}

.alert-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.th-name-row {
  width: 100%;
  max-width: 15%;
  @media (max-width: 768px) {
    max-width: 45%;
  }
}

.copy-alert { 
  position: fixed;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  top: 90%;
  height: fit-content;
}

.with-tool-tip {
  position: relative;
  display: inline-block;
}

.with-tool-tip .tool-tip-text {
  visibility: hidden;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-weight: 300 !important;
  top: -5px;
  right: 110%;
  position: absolute;
  z-index: 1;
}

.tool-tip-left {
  left: 110% !important;
}

.tool-tip-long {
  width: 350px !important;
}

.hidden-on-desktop{
  @media (min-width: 768px) {
    display: none;
  }
}

.cohort-title {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.with-tool-tip:hover .tool-tip-text {
  visibility: visible;
}