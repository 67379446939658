@import './custom';
.content_icon {
  max-width: 6.25rem;
}

.modal-body {
  padding: 0;
}

.orange-border {
  border-style: solid;
  border-color: #f59120;
}

.blue-border {
  border-style: solid;
  border-color: #0e75bb;
}

.background-grey {
  background-color: #d6d6d6;
}

.testimony_text {
  font-weight: 300;
  color: #2bb573;
}

#landing_player {
  margin: 0;
}

.landing_hero {
  color: white;
  .landing_hero_role_text {
    font-size: 2.1rem;
    font-weight: 100;
    margin-bottom: -4px;
  }
  .landing_hero_dashboard_text {
    font-size: 3rem;
  }
  @media only screen and (max-width: 480px) {
    .landing_hero_role_text {
      font-size: 1.75rem;
    }
    .landing_hero_dashboard_text {
      font-size: 3rem;
    }
  }
}

.highlight_image_col {
  font-family: Roboto Slab;
  height: fit-content;
  font-weight: 100;
  color: white;
  background-color: #0e75bb;
}

.landing-container {
  p {
    font-size: 1.3rem;
  }
  li {
    font-size: 1.3rem;
    padding-bottom: 1.3rem;
  }
  // a {
  //   font-size: 1.3rem;
  // }
}

.landing_paragraph_normal {
  font-size: 1.3rem;
}

.contact_button {
  font-family: Roboto Slab;
  font-weight: 300;
  a {
    color: white;
    text-decoration: none;
  }
  background-color: #f59120;
  color: white;
}

.contact_button:hover {
  opacity: 0.9;
}

.learn_more_button {
  border-style: solid;
  border-width: 1.5px;
  // a {
  //   color: black;
  // }
}

.orange-button {
  background-color: rgb(245, 145, 32, .8);
  border-color: rgb(245, 145, 32, 1);
}

.blue-button {
  background-color: rgb(27, 117, 186, .8);
  border-color: rgb(27, 117, 186, 1);
}

.green-button {
  background-color: rgb(39, 179, 115, .8);
  border-color: rgb(39, 179, 115, 1);
}

.purple-button {
  background-color: rgb(101, 47, 142, .8);
  border-color: rgb(101, 47, 142, 1);
}

.landing-button-container {
  text-align: center;
  cursor: pointer;
  color: white;
  a {
    font-family: Roboto Slab;
    font-weight: 300;
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
    border-style: solid;
    border-width: 2px;
    padding: 0.5rem 0.85rem,
  }
  a:hover {
    opacity: 0.75;
    color: white;
  }
}

.landing_display {
  position: relative;

  #landing_player {
    margin: 0;
  }

  .preview_image {
    height: 12rem;
    width: 22rem;
    max-width: 100%;
  }

  .preview_image_play {
    position: absolute;
    left: calc(50% - 45px);
    top: calc(50% - 45px);
    height: 90px;
  }

  .preview_image_play:hover {
    cursor: pointer;
    opacity: 60%;
  }
}

.about_text {
  @media only screen and (min-width: 1024px) {
    line-height: 2;
  }
}

.about_image {
  height: 500px;
}

#partners {
  .landing_hero_role_text {
    text-align: left;
  }

  %section_image {
    overflow: hidden;
    background-position: center;
    background-size: inherit;
    background-repeat: no-repeat;
    background-position-y: bottom;
    max-width: 100%;
    padding: 0;
  }

  .key_talent_img {
    @extend %section_image;
    background-image: url('/Partners-Talent_Retention.svg');
    background-color: rgb(245, 146, 34);

    @media only screen and (max-width: 991px) {
      height: 20rem;
    }
  }

  .belonging_img {
    @extend %section_image;
    background-image: url('/Partners-Belonging.svg');
    background-color: rgb(27, 117, 186);

    @media only screen and (max-width: 991px) {
      height: 20rem;
    }
  }

  .wellbeing_img {
    @extend %section_image;
    background-image: url('/Partners-Wellbeing.svg');
    background-color: rgb(41, 179, 115);
    background-size: cover;
    background-position-x: left;

    @media only screen and (max-width: 991px) {
      height: 20rem;
    }
  }

  .innovation_img {
    @extend %section_image;
    background-image: url('/Partners-Innovation.svg');
    background-color: rgb(245, 146, 34);

    @media only screen and (min-width: 660px) and (max-width: 991px) {
      background-size: cover;
    }

    @media only screen and (max-width: 991px) {
      height: 20rem;
    }
  }

  .leadership_img {
    @extend %section_image;
    background-image: url('/Partners-Leadership.svg');
    background-color: rgb(27, 117, 186);

    @media only screen and (max-width: 991px) {
      height: 20rem;
    }
  }

  .talent_content_icon img {
    min-width: 4rem !important;
    width: 4rem !important;
  }

  .contact_us {
    p {
      text-align: center;
    }
  }

  .scrollTop {
    flex-direction: column;
    position: fixed;
    width: 7rem;
    height: 3rem;
    bottom: 20px;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.2;
    right: 20px;
    outline: none;
    text-decoration: none;
    font-style: italic;
    border: 1px solid grey;

    .icon {
      color: gray;
    }
  }

  .scrollTop:hover {
    opacity: 0.4;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.2;
    }
  }
}
