$red: #be1e2d;
$orange: #f79420;
$yellow: #fff100;
$green: #2bb573;
$blue: #0e75bb;
$purple: #652d90;

$gray-200: #eaeaea;
$gray-300: #d6d6d6;
$gray-400: #cccccc;
$gray-500: #bbbbbb;
$gray-600: #909090;
$gray-900: #212529;

$border-radius: 0;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;

$font-family-sans-serif: Roboto;
$font-size-base: 1rem;
$font-weight-normal: 300;
$font-weight-base: 300;
$headings-font-weight: 300;

$headings-font-family: 'Roboto Slab';
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
  6: (
    $spacer * 5,
  ),
  7: (
    $spacer * 8,
  ),
  8: (
    $spacer * 12,
  ),
);

@import '~bootstrap/scss/bootstrap';
@import 'react-range-slider-input/dist/style.css';


$min-font-size: 14px;
$normal-font-size: 16px;
$max-font-size: 18px;

// Scale the base font size based on the screen size
@include media-breakpoint-up(xs) {
  html {
    font-size: $min-font-size;
  }
}
@include media-breakpoint-up(sm) {
  html {
    font-size: $min-font-size;
  }
}
@include media-breakpoint-up(md) {
  html {
    font-size: $normal-font-size;
  }
}
@include media-breakpoint-up(lg) {
  html {
    font-size: $normal-font-size;
  }
}
@include media-breakpoint-up(xl) {
  html {
    font-size: $max-font-size;
  }
}
