.cell {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.5em 1em;
  border: solid 1px #999;
  transition: all cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.4s;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: #d9eaf1;
  }
  p {
    margin-bottom: 0;
    &.spacer {
      margin: 0 0.5em;
    }
  }
  .upper-text {
    display: flex;
    justify-content: flex-start;
    p {
      font-size: 0.6875em;
    }
  }
  .lower-text {
    display: flex;
    justify-content: flex-start;
    opacity: 55%;
    p {
      font-size: 0.5em !important;
    }
  }
}

.cell.selected {
  background-color: #d9eaf1;
}

.contactListDropDown {
  max-height: 120px;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  top: 2.3em;
  z-index: 10;
  border: solid 1px #999;
  list-style-type: none;
  padding-left: 0;
  button {
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
  }
}
