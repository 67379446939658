@import './custom';

.landing {
  position: relative;
  overflow: hidden;
  p {
    text-align: center;
  }

  button {
    border-radius: $border-radius;
  }

  .desktop_br {
    display: none;
  }

  @media only screen and (min-width: 991px) {
    .mobile_br {
      display: none;
    }

    .desktop_br {
      display: inline;
    }
  }
}

.graph_image {
  //background-image: url('/LND-website-header-graphic.svg');
  width: 100vw;
  height: 100vh;
  min-height: 1200px;
  position: absolute;
  left: 0;
  background-repeat: no-repeat;
  background-position: 25vw 13vh;
  z-index: -10;

  @media only screen and (min-width: 640px) {
    background-size: 70%;
    background-position-x: 40vw;
  }

  @media only screen and (min-width: 1024px) {
    background-size: 70%;
    background-position: 42vw 1vh;
  }
}

#testimonial_player {
  position: static;
}

.initial_section {
  overflow: hidden;
}

.header {
  .header_login_col {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button,
    .btn-group button {
      font-size: 1.25rem;
      width: 7rem;
      border-radius: 0 !important;
      height: fit-content;
      min-height: 3rem;
      background-color: white;
      border-color: $blue;
      color: $blue;
    }

    .login_button:hover,
    .btn-group button:hover {
      background-color: $blue;
      color: white;
    }

    .logout_button {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn-group .dropdown-item {
      width: 100%;
      text-align: center;
    }
  }
}

.network_icon {
  .network_icon_image {
    width: 15rem;
  }
}

.hero {
  background-color: $green;
  color: white;
  margin: 0;

  .hero_bold_text {
    font-weight: 400;
  }

  .hero_small_text {
    font-family: Roboto Slab;
    font-size: 1rem;
    text-align: center;
  }
}

.middle_section {
  background-color: $blue;
  color: white;

  .middle_image {
    width: 10rem;
    // padding-bottom: 2.5rem;
  }
}

.testimonials_section {
  .testimonial_col {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 3vw;
  }

  .testimonial_header {
    padding: 0 0.5rem 0 0.5rem;
  }

  .testimonial_video_title_container {
    text-align: center;
  }

  .testimonial_video_title {
    font-size: 1.5rem;
    font-weight: 300;
  }

  .preview_image {
    width: 75%;
  }

  .preview_image_play {
    position: absolute;
    width: 15%;
    top: 15%;
    cursor: pointer;
  }

  .preview_image_play:hover {
    opacity: 60%;
  }

  @media only screen and (max-width: 480px) {
    .testimonial_video_title {
      font-size: 1.5rem;
    }
  }

  @media only screen and (min-width: 991px) {
    .row .testimonial_col:nth-of-type(even) {
      align-items: flex-start;
      .testimonial_video_title_container {
        margin-left: 30%;
      }
      .preview_image_play {
        left: 30%;
      }
    }
    .row .testimonial_col:nth-of-type(odd) {
      align-items: flex-end;
      .testimonial_video_title_container {
        margin-right: 30%;
      }
      .preview_image_play {
        right: 30%;
      }
    }
  }
}

.need_help {
  position: relative;
  bottom: 4rem;

  h3 {
    padding: 0 1rem 0;
  }

  a {
    color: black;
  }

  .help_email {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .help_email img {
    height: 2rem;
    padding-right: 0.25rem;
    padding-bottom: 0.1rem;
  }
}
