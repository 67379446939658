table {
  table-layout: fixed;
  width: 100%;
}

.icon-row {
  margin-left: 10%;
  width: 87%;
  display: flex;
}

table,
td,
th,
tr {
  font-size: medium;
  border: 1px solid #8a8a8a !important;
  overflow-x: auto;
  text-align: center;
}

th.first {
  color: black;
  font-weight: bold;
}

th {
  padding: 0 4px;
  font-family: 'Roboto';
  font-weight: 200;
  background-color: #ebebec;
}

td.blank {
  background-color: #ebebec;
}

.icon-block {
  flex-direction: column;
  text-align: center;
  width: 23%;
  border-left: 1pt solid #8a8a8a;
  color: #26b373;
}

.icon-block.last {
  border-right: 1pt solid #8a8a8a;
}

table {
  margin-top: 5px;
}

svg.reference {
  margin: 0 auto;
  width: 101%;
}

.inner-resources-component-mobile {
  display: none;
}

inner-resources-component {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .icon-row {
    margin: 10% 0 10% 5%;
    width: 100%;
  }

  table {
    margin: 10% 0%;
  }

  .inner-resources-component {
    display: none;
  }

  .inner-resources-component-mobile {
    display: block;
  }

  .first-cell {
    width: 100px;
  }

  .regular-cell {
    font-size: 7pt;
  }

  .value-row {
    min-height: 29px;
    font-size: 13pt;
    color: gray;
    font-weight: bold;
  }

  .cell-text {
    line-height: 15px;
  }

  .connection-cell {
    font-size: 9pt;
    font-weight: bold;
  }
}
