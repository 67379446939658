.cohort_statistics_container {
  h1 {
    font-family: Roboto;
  }
  ul {
    list-style-type: none;
  }

  .cohort_statistics_main_alert,
  .cohort_statistics_main_loading {
    height: 100vh;
    .cohort_statistics_main_alert_box {
      height: 10vh;
    }
  }

  .cohort_statistics_icon {
    width: 5rem;
    margin-right: 1.5rem;
  }

  .cohort_statistics_list {
    line-height: 60%;
  }

  .cohort_statistics_diagnostic_col {
    .cohort_statistics_diagnostic {
      position: relative;
      top: -2rem;
      width: 10rem;
    }
  }

  .cohort_statistics_question,
  .cohort_statistics_network_item_title {
    font-size: 1.5rem;
    p {
      font-size: 1.5rem;
    }
  }

  .cohort_statistics_network_icon {
    width: 4rem;
    margin-right: 1.5rem;
    margin-left: -1px;
  }

  .cohort_statistics_main_icon {
    width: 4rem;
    margin-right: 1.5rem;
  }

  .cohort_statistics_network_average {
    color: rgb(167, 5, 5);
    font-weight: 500;
    font-size: 1.7rem;
  }

  .cohort_statistics_percentage {
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.goals-wrap {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  margin-top: 3rem !important;
}
