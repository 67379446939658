@use 'sass:math';
@import './custom';

.container-responsive-height {
  height: 850px;
}

.content-box {
  box-sizing: content-box;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .container-responsive-height {
    height: 800px;
  }
}

footer.button-nav {
  background-color: $white;
  padding-top: math.div($grid-gutter-width, 2);
  padding-bottom: math.div($grid-gutter-width, 2);
}

p,
li {
  font-size: 1rem;
}

.heading-shadow {
  text-shadow: 2px 2px #3d3939;
}

.tab-content {
  padding: 1rem;
}

.form-range {
  .row.no-gutters {
    padding: 5px 9px;
  }

  .tickmark {
    height: 10px;
    border-left: 2px solid black;

    &:last-child {
      border-right: 2px solid black;
    }
  }
}

.react-player-wrapper {
  position: relative;
  padding-top: math.div(100%, math.div(1280, 720));
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.btn-group > .btn {
  border-radius: 0.25rem !important;
}

.btn {
  border-radius: 0.25rem;
  cursor: pointer;
}

#videoModal {
  max-width: 90%;
}

#videoModal > div > div.modal-body > div {
  width: 100%;
  height: 100%;
  margin: 0px auto;
}

#videoModal > div > div.modal-body > div > div {
  position: relative;
  padding-bottom: 56.3%;
  height: 0px;
}

#videoModal > div > div.modal-body > div > div > iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.wrapper-show {
  position: relative;
  padding-top: 5px;
  padding-left: 5px;
  margin-left: 5px;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: stretch;
  align-items: stretch;
  flex-direction: row;

  & > div {
    width: 100%;
  }
}

.wrapper-full {
  width: 100%;
  padding-left: 0px;
  margin: 0 !important;
}

@media only screen and (min-width: 768px) {
  .wrapper-show > div {
    width: 45%;
  }

  .wrapper-full > div {
    width: 100% !important;
  }
}

.wrapper-img {
  position: absolute;
  right: 3px;
  width: 1.2em;
}

.wrapper-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #be1e2d;
}

.wrapper-invalid {
  border-color: #be1e2d;
  .validated-form {
    margin-top: 1rem;
    margin-bottom: -1rem !important;
  }
  
}

.wrapper-valid {
  border-color: #2bb573;
  .validated-form {
    margin-top: 1rem;
    margin-bottom: -1rem !important;
  }
}

.NWLSpinner {
  font-family: Roboto;
  display: flex;
  align-items: flex-end;
  font-weight: 400;
  font-style: italic;
  color: #0e75bb;
  .NWLSpinner_icon {
    animation: spinner-border 0.75s linear infinite;
  }
}

.confirmation_dialog_button {
  width: 4rem;
}

.w-full {
  width: 100% !important;
}

.text-lg {
  font-size: 32px;
}

.text-md {
  font-size: 25px;
}

.justify-between {
  justify-content: space-around;
}

.bg-green {
  background-color: $green !important;
}

.bg-blue {
  background-color: $blue !important;
}

.hide {
  display: none !important;
}

.items-center {
  align-items: center;
}

.action-header {
  border: 1px solid;
  padding: 1rem;
}

.action-bar {
  border-bottom: 1px solid;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.text-blue {
  color: $blue;
}

.bg-back-button {
  background-color: #f79420 !important;
  border-color: #f79420 !important;
  color: #fff !important;
}

.bg-back-button:hover {
  background-color: #df7e0f;
  color: #fff;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.progress-bar-green {
  background-color: red !important;
}

// User Profile css

.user-profile-surveye-row {
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 1rem;
  justify-content: space-between;
  display: flex;
  margin-top: 2rem;
  justify-items: center;
  flex-wrap: wrap;
}

.user-survey-row {
  min-width: 45%;
}

.user-surveys {
  padding-top: 2rem;
  padding-right: 2rem;
  width: 70%;
}

.user-survey-date {
  font-size: 1.2rem;
  min-width: 6rem;
}

.user-materials {
  padding-top: 2rem;

  width: 30%;
}

.user-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
}

.user-section-title {
  border-bottom: 1px solid $blue;
  font-size: 1.8rem;
}

.user-top-banner {
  padding: 2rem;
  color: white;
  justify-content: space-between;
  justify-items: center;
  flex-wrap: wrap;
}

.user-display-name {
  font-size: 2rem;
}

.user-download-button {
  font-size: 1.2rem;
  cursor: pointer;
  justify-items: baseline;
}

.user-view-report {
  font-size: 1.2rem !important;
  margin-left: 1.2rem;
}

.user-survey-list {
  display: flex;
  flex-direction: column-reverse;
}

.profile-survey-buttons > button:nth-child(2n+1) {
  margin: 0 0.25rem;
}

@media (max-width: 550px) {
  .profile-survey-buttons {
    margin-top: 1rem;
    align-self: end;
  }

  .user-profile-surveye-row {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .user-survey-date {
    font-size: 1rem;
    min-width: 5rem;
  }

  .user-profile-surveye-row > * {
    font-size: 16px !important;
  }

  .user-display-name {
    font-size: 1.5rem;
  }

  .user-download-button {
    font-size: 1rem;
  }

  .user-view-report {
    font-size: 1rem !important;
    margin-left: 1rem;
  }

  .user-survey-row {
    justify-content: space-between;
  }
}

@media (max-width: 870px) {
  .user-surveys {
    width: 100%;
    padding-right: 0rem;
  }

  .user-materials {
    width: 100%;
  }
}

.border-0 {
  border-radius: 0 !important;
}

.inline {
  display: inline;
}

.bg-transparent {
  background-color: transparent;
}

.p-0 {
  padding: 0;
}

.text-black {
  color: #000 !important;
}

.font-base {
  font-weight: $font-weight-base;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.drop-down-input {
  display: flex;
  align-items: center;
  font-weight: 500;
  max-width: 90vw;
  width: 400px;
  padding: .5rem;
}

.range-slider .range-slider__thumb .range-slider .range-slider__range {
  background: $blue !important;
}


.drop-down-container{
  margin-top: .5rem;
  border: solid 1px $gray-300;
  border-radius: 8px;
}

.drop-down-options{
  display: none;
}

.drop-down-open {
  display: flex !important;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
}

.drop-down-option {
  margin-top: .2rem;
  margin-bottom: .2rem;
}

.drop-down-input-active {
  border-bottom: solid 1px $gray-300;
}

.dropdown-asset {
  margin-right: .5rem;
}

.dropdown-asset-active {
  rotate: 90deg;
}

.statistics-wrapper{ 
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  max-width: 100vw;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  max-width: 1200px;
}

@media (max-width: 990px) {
  .statistics-wrapper {
    flex-wrap: wrap;
  }

  .statistics {
    margin-top: 2rem;
  }
}

.statistics {
  width: 100%;
}

.statistics .cohort_statistics_main_alert, .cohort_statistics_container {
  height: auto !important;
}

.statistics .cohort_statistics_main_alert {
  margin-top: 10rem;
}

.step-icon {
  background: rgb(211, 211, 211);
  border: solid 3px white;
  border-radius: 9999999px;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.step-icon-active {
  background: rgb(43, 194, 83) !important;
}

.step-icon-text{
  padding-top: .5rem;
  text-align: center;
}

@media (max-width: 768px){
  .step-icon-text{
    display: none !important;
  }
  .step-icon-container {
    padding-top: 0 !important;
  }
}

.step-icon-container {
  // position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
}

.RSPBprogressBar {
  height: 20px !important;
  border-radius: 0px !important;
}
.RSPBprogression {
  border-radius: 0px !important;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 12.5%,
    transparent 12.5%,
    transparent 25%,
    rgba(255, 255, 255, 0.2) 25%,
    rgba(255, 255, 255, 0.2) 37.5%,
    transparent 37.5%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 62.5%,
    transparent 62.5%,
    transparent 75%,
    rgba(255, 255, 255, 0.2) 87.5%,
    transparent 87.5%,
    transparent
  ) !important;
}

.text-sm {
  font-size: $min-font-size;
}

.statistics-count {
  display: flex;
  margin-left: 2rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
}


@media (max-width: 768px){
  .statistics-count{
    display: block !important;
  }
}
