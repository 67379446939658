$depth-blue: #1b75ba;
.depth-tagline {
  padding: 2%;
  display: flex;
  background-color: $depth-blue !important;
  -webkit-print-color-adjust: exact;
}

.tagline-left {
  width: 40%;
  color: white;
  display: inline-block;
}

.tagline-right {
  width: 60%;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .depth-tagline {
    flex-direction: column;
  }
  .tagline-left,
  .tagline-right {
    width: 100%;
  }
  .diversity-column-small {
    width: 100%;
    margin-bottom: 5%;
  }
}
